<template>
  <Dialog
    v-model:visible="mostrarModal"
    :modal="true"
    :style="{ width: '1200px' }"
    header="Productos encontrados"
    class="p-fluid"
    @hide="cancelar"
  >
    <div class="col-12">
      <div class="">
        <DataTable
          :value="data_productos"
          class="p-datatable-sm"
          v-model:expandedRows="expandedRows"
          showGridlines
          selectionMode="single click"
          :filters="buscar"
          @row-dblclick="productos_selected.push($event.data)"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar Producto..."
                />
              </span>
            </div>
          </template>
          <Column style="width: 1.5rem">
            <template #header>
              <i
                class="pi pi-check-circle ml-4"
                v-tooltip.top="'Seleccionar Productos'"
              ></i>
            </template>
            <template #body="slotProps">
              <Checkbox
                class="ml-3"
                v-model="productos_selected"
                :value="slotProps.data"
                v-tooltip.top="'Seleccionar Producto'"
              />
            </template>
          </Column>
          <Column
            field="descripcion"
            header="DESCRIPCIÓN/DETALLE"
            :sortable="true"
            style="width: 250px; font-weight: bold"
          ></Column>
          <Column
            field="codigo_origen"
            header="CÓD.ORIGEN"
            class="white-space-nowrap"
          >
            <template #body="slotProps">
              <span>{{
                slotProps.data.codigo_origen
                  ? slotProps.data.codigo_origen
                  : "-"
              }}</span>
            </template>
          </Column>
          <Column
            field="medidas"
            header="DIMENSIONES"
            class="white-space-nowrap"
          >
            <template #body="slotProps">
              <span>{{
                slotProps.data.medidas ? slotProps.data.medidas : "-"
              }}</span>
            </template>
          </Column>
          <Column
            field="fabrica_nombre"
            header="MARCA"
            class="white-space-nowrap"
          >
            <template #body="slotProps">
              <span>{{
                slotProps.data.fabrica_nombre
                  ? slotProps.data.fabrica_nombre
                  : "-"
              }}</span>
            </template>
          </Column>
          <Column
            field="ubicacion"
            header="UBICACIÓN"
            class="white-space-nowrap"
          >
            <template #body="slotProps">
              <span>{{
                slotProps.data.ubicacion ? slotProps.data.ubicacion : "-"
              }}</span>
            </template>
          </Column>
          <Column expander header="MAS INFO..." style="width: 5rem" />
          <template #expansion="slotProps">
            <div class="p-3">
              <div class="grid">
                <div class="col-2">
                  <p for="codigo_equivalente">
                    <strong>CÓD. EQUIV</strong>
                  </p>
                  {{ slotProps.data.codigo_equivalente }}
                </div>
                <div class="col-2">
                  <p for="codigo_interno">
                    <strong>C&Oacute;D. INTERNO</strong>
                  </p>
                  {{ slotProps.data.codigo_interno }}
                </div>
                <div class="col-2">
                  <p for="caso_uso"><strong>OBSERVACIONES: </strong></p>
                  {{ slotProps.data.caso_uso ?? "-" }}
                </div>
                <div class="col-2">
                  <p for="nombre_grupo"><strong>GRUPO : </strong></p>
                  {{ slotProps.data.nombre_grupo }}
                </div>
                <div class="col-2">
                  <p for="procedencia"><strong>PROCEDENCIA : </strong></p>
                  {{ slotProps.data.procedencia_nombre }}
                </div>
                <div class="col-2">
                  <template v-if="slotProps.data.imagen != null">
                    <Image
                      :src="'data:image/png;base64,' + slotProps.data.imagen"
                      width="70"
                      preview
                    ></Image>
                  </template>
                  <template v-else><p>SIN IMAGEN</p></template>
                </div>
              </div>
            </div>
          </template>
          <!-- <Column field="codigo_barra" header="Código Barra" :sortable="true" /> -->
          <Column
            field="unidad_medida_nombre"
            header="U. MEDIDA"
            :sortable="true"
            class="white-space-nowrap"
          />
          <!-- <Column field="fabrica_nombre" header="MARCA" :sortable="true" /> -->
          <Column
            field="precio_sugerido"
            class="text-2xl bg-blue-100 text-right white-space-nowrap"
            header="PRECIO"
            :sortable="true"
          >
            <template #body="slotProps">
              <span v-if="esCompra">
                {{ slotProps.data.precio_compra }}
              </span>
              <span v-else>{{ slotProps.data.precio_sugerido }}</span>
            </template>
          </Column>
          <Column
            header="STOCK"
            class="text-right white-space-nowrap font-bold"
            :sortable="true"
          >
            <template #body="slotProps">
              <span
                v-if="
                  slotProps.data.stock_actual == null ||
                  slotProps.data.stock_actual == 0
                "
              >
                <span
                  class="text-red-500"
                  style="font-weight: bold; font-size: 1.2rem"
                >
                  {{ slotProps.data.stock_actual ?? 0 }}
                </span>
              </span>
              <span v-else>
                {{ slotProps.data.stock_actual ?? 0 }}
              </span>
              <!-- <span>{{ slotProps.data.stock[0].cantidad == undefined ? 0 : slotProps.data.stock[0].cantidad }}</span> -->
              <!-- <span>{{ slotProps.data.stock_actual ?? 0 }}</span> -->
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <template #footer>
      <div>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger p-button-lg"
          @click="cancelar"
        />
        <Button
          label="AÑADIR PRODUCTOS"
          icon="pi pi-shopping-cart"
          class="p-button-primary p-button-lg"
          @click="agregarProductos"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
export default {
  emits: ["closeModal", "agregarProductos"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    compra: {
      type: Boolean,
      default: false,
    },
    productos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_productos: this.productos,
      productos_selected: [],
      expandedRows: [],
      esCompra: this.compra,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },

  methods: {
    cancelar() {
      this.$emit("closeModal");
    },
    marcarTodos() {
      this.productos_selected = this.data_productos.map((item) => item.id);
    },
    agregarProductos() {
      this.$emit("agregarProductos", this.productos_selected);
      this.productos_selected = [];
    },
    /* buscar_producto_del_detalle(){
      this.detalleProductos.forEach((item) => {
        //Busco el producto en el listado
        console.log('item',item);
        let producto = this.data_productos.find(
          (prod) => prod.id == item.id
        );
        //Ingresar a productos seleccionados
        this.productos_selected.push(producto);
      });
    } */
  },

  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    compra(value) {
      this.esCompra = value;
    },
    productos(value) {
      this.data_productos = value;
      /* this.buscar_producto_del_detalle(); */
    },
  },
};
</script>
